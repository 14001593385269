export default {
    data() {
        return {
            resfresh: true,
        };
    },
    methods: {
        changePhoto(e) {
            this.resfresh = false;
            this.item.image = e;
        },
    },
};
